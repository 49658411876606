import { appPaths } from '@app/router/paths'
import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface ISearchInput {
  className?: string
  placeholder?: string
}

function SearchInput({ className, placeholder }: ISearchInput) {
  const navigate = useNavigate()
  const location = useLocation()
  const searchResultRef = useRef<HTMLInputElement>(null)
  const handleSearch = () => {
    const query = searchResultRef.current?.value
    if (location.pathname == appPaths.journal.topPage.museum) {
      navigate(`journal/search-result/museum/?q=${query}`)
    } else if (location.pathname == appPaths.journal.topPage.forum) {
      navigate(`journal/search-result/forum/?q=${query}`)
    } else {
      navigate(`journal/search-result/home/?q=${query}`)
    }
  }

  const [composing, setComposing] = useState<boolean>(false)

  return (
    <div className={`search-input flex ${className}`}>
      <input
        ref={searchResultRef}
        placeholder={placeholder}
        className="input"
        onKeyUp={(e) => {
          if (composing) {
            return
          }
          if (e.keyCode === 13 || e.key === 'Enter') {
            handleSearch()
          }
        }}
        onCompositionStart={() => {
          setComposing(true)
        }}
        onCompositionEnd={() => {
          setComposing(false)
        }}
        style={{ textAlign: 'center' }}
        enterKeyHint="go"
      />
      <img
        onClick={handleSearch}
        src="/assets/svg/search-icon.svg"
        alt="search-icon"
        className="search-icon cursor-pointer"
      />
    </div>
  )
}

export default SearchInput
