import { TOKEN_KEY } from '@app/config/const'
import LoadContent from '@components/common/LoadContent'
import StorageServices from '@services/local.storage'
import { cartApi } from '@store/api/cart.api'
import { customerApi } from '@store/api/customer.api'
import { useAppDispatch } from '@store/index'
import { logout } from '@store/slices/auth.slice'
import React, { Suspense, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { authPaths, bookSeller } from './paths'

const PrivateRoute = ({ children }: any) => {
  const { pathname } = useLocation()

  const dispatch = useAppDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  if (!StorageServices.getData(TOKEN_KEY, null)) {
    dispatch(customerApi.util.resetApiState())
    dispatch(cartApi.util.resetApiState())
    dispatch(logout())

    if (pathname.includes('journal'))
      return (
        <Navigate
          to={authPaths.journal.login}
          replace
        />
      )
    return (
      <Navigate
        to={authPaths.bookSeller.signIn}
        replace
      />
    )
  }
  return <Suspense fallback={<LoadContent />}>{children}</Suspense>
}

export default React.memo(PrivateRoute)
