import { FunctionComponent, lazy, LazyExoticComponent } from 'react'

import { appPaths, authPaths, bookSeller } from './paths'

const SignInView = lazy(() => import('@views/auth-views/sign-in'))
const SignUpView = lazy(() => import('@views/auth-views/sign-up'))
const MemberShip = lazy(
  () => import('@views/auth-views/member-ship/MemberShip')
)
const ForgotPassword = lazy(
  () => import('@views/auth-views/forgot-password/ForgotPassword')
)
const ResetPassword = lazy(
  () => import('@views/auth-views/reset-password/ResetPassword')
)

const VerifyEmail = lazy(
  () => import('@views/auth-views/verify-email/VerifyEmail')
)
const VerifyNewEmail = lazy(
  () => import('@views/auth-views/verify-new-email/VerifyNewEmail')
)

const RegisterView = lazy(() => import('@views/auth-views-jounal/register'))
const RegisterSuccessView = lazy(
  () => import('@views/auth-views-jounal/registerSuccess')
)
const LoginView = lazy(() => import('@views/auth-views-jounal/login'))
const JounalForgotPasswordView = lazy(
  () => import('@views/auth-views-jounal/forgot-password')
)
const JounalResetPasswordView = lazy(
  () => import('@views/auth-views-jounal/reset-password')
)
const JounalRegisterPaidMemberShipView = lazy(
  () => import('@views/auth-views-jounal/register-paid-member-ship')
)

const JounalPaymentMethodView = lazy(
  () => import('@views/auth-views-jounal/payment-method')
)
const JounalRegisterPaidMemberShipCompleteView = lazy(
  () => import('@views/auth-views-jounal/register-paid-member-ship/Complete')
)
const JounalRegisterPaidMemberShipRegisteredView = lazy(
  () => import('@views/auth-views-jounal/register-paid-member-ship/Registered')
)

const JounalCancelFreeMemberSuccessView = lazy(
  () =>
    import('@views/app-views/jounal/cancel-free-member/CancelFreeMemberSuccess')
)

const JounalVerifyEmail = lazy(
  () => import('@views/auth-views-jounal/verify-email/VerifyEmail')
)
const JounalVerifyNewEmail = lazy(
  () => import('@views/auth-views-jounal/verify-new-email/VerifyNewEmail')
)

const JounalAccountView = lazy(
  () => import('@views/app-views/jounal/account/customer-information')
)

const HomePage = lazy(
  () => import('@views/app-views/jounal/home-page/HomePage')
)

const TopPage = lazy(() => import('@views/app-views/jounal/top-page/TopPage'))

const ForumPage = lazy(
  () => import('@views/app-views/jounal/top-page-forum/TopPageForum')
)

const JounalDetailArticle = lazy(
  () => import('@views/app-views/jounal/detail-article/DetailArticle')
)

const JounalListAuthorEditor = lazy(
  () => import('@views/app-views/jounal/author-editor/List')
)

const JounalDetailAuthorEditor = lazy(
  () => import('@views/app-views/jounal/author-editor/Detail')
)

const SearchResult = lazy(
  () => import('@views/app-views/jounal/search-result/SearchResult')
)

// Book seller
const CustomerInformation = lazy(
  () =>
    import(
      '@views/app-views/book-seller/account/customer-information/CustomerInformation'
    )
)
const GenkenBookSeller = lazy(
  () => import('@views/app-views/book-seller/book-seller/BookSeller')
)

const CategoryList = lazy(
  () => import('@views/app-views/book-seller/category-list/CategoryList')
)

const BookDetail = lazy(
  () => import('@views/app-views/book-seller/book-detail')
)
const CartView = lazy(() => import('@views/app-views/book-seller/cart'))

const AuthorListView = lazy(
  () => import('@views/app-views/book-seller/seller/Seller')
)

const AuthorDetailPage = lazy(
  () => import('@views/app-views/book-seller/seller-detail/SellerDetail')
)

const ReadBook = lazy(
  () => import('@views/app-views/book-seller/read-book/ReadBook')
)

const Publishers = lazy(
  () => import('@views/app-views/book-seller/publisher/Publisher')
)

const RecommendBook = lazy(
  () => import('@views/app-views/book-seller/recommend-book/RecommendBook')
)

const PublishersDetail = lazy(
  () => import('@views/app-views/book-seller/publisher-detail/PublisherDetail')
)

const BookAboutUs = lazy(() => import('@views/app-views/page/about/BookAbout'))

const JournalAboutUs = lazy(
  () => import('@views/app-views/page/about/JounalAbout')
)

const BookQuestionAnswer = lazy(
  () => import('@views/app-views/page/question-answer/QuestionAndAnswer')
)

const TermOfService = lazy(
  () => import('@views/app-views/page/term-of-service/TermOfService')
)
const SpecificelCommercialTransaction = lazy(
  () =>
    import(
      '@views/app-views/page/specificed-commercial-transaction/SpecificedCommercialTransaction'
    )
)
const PrivacyPolicy = lazy(
  () => import('@views/app-views/page/privacy-policy/PrivacyPolicy')
)
const BooksellerCancelFree = lazy(
  () =>
    import(
      '@views/app-views/book-seller/bookseller-cancel-free-member/BookSellerCancelFreeMemberSuccess'
    )
)

interface Route {
  key: string
  path: string
  component: LazyExoticComponent<FunctionComponent | React.FC>
  public: boolean
}

export const routes: Route[] = [
  {
    key: 'sign-in',
    path: authPaths.bookSeller.signIn,
    component: SignInView,
    public: true
  },
  {
    key: 'sign-up',
    path: authPaths.bookSeller.signUp,
    component: SignUpView,
    public: true
  },
  {
    key: 'member-ship',
    path: authPaths.bookSeller.memberShip,
    component: MemberShip,
    public: true
  },
  {
    key: 'forgot-password',
    path: authPaths.bookSeller.forgotPassword,
    component: ForgotPassword,
    public: true
  },
  {
    key: 'reset-password',
    path: authPaths.bookSeller.resetPassword,
    component: ResetPassword,
    public: true
  },
  {
    key: 'verify-email',
    path: authPaths.bookSeller.verifyEmail,
    component: VerifyEmail,
    public: true
  },
  {
    key: 'verify-new-email',
    path: authPaths.bookSeller.verifyNewEmail,
    component: VerifyNewEmail,
    public: true
  },
  // journal
  {
    key: 'journal-auth-register',
    path: authPaths.journal.register,
    component: RegisterView,
    public: true
  },
  {
    key: 'journal-auth-register-success',
    path: authPaths.journal.registerSuccess,
    component: RegisterSuccessView,
    public: true
  },
  {
    key: 'journal-auth-login',
    path: authPaths.journal.login,
    component: LoginView,
    public: true
  },
  {
    key: 'journal-auth-verify-email',
    path: authPaths.journal.verifyEmail,
    component: JounalVerifyEmail,
    public: true
  },
  {
    key: 'journal-auth-verify-new-email',
    path: authPaths.journal.verifyNewEmail,
    component: JounalVerifyNewEmail,
    public: true
  },
  {
    key: 'journal-auth-forgot-password',
    path: authPaths.journal.forgotPassword,
    component: JounalForgotPasswordView,
    public: true
  },
  {
    key: 'journal-auth-reset-password',
    path: authPaths.journal.resetPassword,
    component: JounalResetPasswordView,
    public: true
  },
  {
    key: 'journal-paid-member-ship',
    path: authPaths.journal.paidMemberShip,
    component: JounalRegisterPaidMemberShipView,
    public: false
  },
  {
    key: 'journal-payment-method',
    path: authPaths.journal.paymentMethod,
    component: JounalPaymentMethodView,
    public: false
  },

  {
    key: 'paid-member-ship-complete',
    path: authPaths.journal.paidMemberShipComplete,
    component: JounalRegisterPaidMemberShipCompleteView,
    public: false
  },
  {
    key: 'paid-member-ship-registered',
    path: authPaths.journal.paidMemberShipRegistered,
    component: JounalRegisterPaidMemberShipRegisteredView,
    public: false
  },
  {
    key: 'cancel-free-member-success',
    path: appPaths.journal.freeMember.cancelSuccess,
    component: JounalCancelFreeMemberSuccessView,
    public: true
  },
  // journal account
  {
    key: 'journal-account-infomation',
    path: appPaths.journal.account,
    component: JounalAccountView,
    public: false
  },
  // Home page
  {
    key: 'home',
    path: '',
    component: HomePage,
    public: true
  },
  {
    key: 'top-page',
    path: appPaths.journal.topPage.museum,
    component: TopPage,
    public: true
  },
  {
    key: 'top-page-forum',
    path: appPaths.journal.topPage.forum,
    component: ForumPage,
    public: true
  },
  {
    key: 'detail-article',
    path: appPaths.journal.article.detail,
    component: JounalDetailArticle,
    public: true
  },
  {
    key: 'list-author',
    path: appPaths.journal.author.list,
    component: JounalListAuthorEditor,
    public: true
  },
  {
    key: 'list-editor',
    path: appPaths.journal.editor.list,
    component: JounalListAuthorEditor,
    public: true
  },
  {
    key: 'detail-author',
    path: appPaths.journal.author.detail,
    component: JounalDetailAuthorEditor,
    public: true
  },
  {
    key: 'detail-editor',
    path: appPaths.journal.editor.detail,
    component: JounalDetailAuthorEditor,
    public: true
  },
  {
    key: 'search-result',
    path: appPaths.journal.search.searchResult,
    component: SearchResult,
    public: true
  },
  // Account
  {
    key: 'account-setting',
    path: authPaths.bookSeller.accountSetting,
    component: CustomerInformation,
    public: false
  },
  {
    key: 'geneken-book-seller',
    path: bookSeller.bookSeller,
    component: GenkenBookSeller,
    public: true
  },
  {
    key: 'geneken-category-list',
    path: bookSeller.categoryList,
    component: CategoryList,
    public: true
  },
  {
    key: 'cancel-free-member-book-seller',
    path: bookSeller.cancelFreeMemberSuccess,
    component: BooksellerCancelFree,
    public: true
  },
  // Book
  {
    key: 'book-detail',
    path: bookSeller.bookDetail,
    component: BookDetail,
    public: true
  },
  //Cart
  {
    key: 'cart',
    path: bookSeller.cart,
    component: CartView,
    public: true
  },
  // Author
  {
    key: 'author',
    path: bookSeller.authors,
    component: AuthorListView,
    public: true
  },
  // Author detail
  {
    key: 'author-detail',
    path: bookSeller.authorDetail,
    component: AuthorDetailPage,
    public: true
  },
  {
    key: 'search-list',
    path: bookSeller.searchList,
    component: CategoryList,
    public: true
  },
  //Read-book
  {
    key: 'read-book',
    path: bookSeller.readBook,
    component: ReadBook,
    public: true
  },
  {
    key: 'limit-book',
    path: bookSeller.viewBook,
    component: ReadBook,
    public: true
  },
  // publisher
  {
    key: 'publisher',
    path: bookSeller.publisher,
    component: Publishers,
    public: true
  },
  // publisher detail
  {
    key: 'publisher-detail',
    path: bookSeller.publisherDetail,
    component: PublishersDetail,
    public: true
  },
  // recommend-book book
  {
    key: 'recommend-book',
    path: bookSeller.recommendBook,
    component: RecommendBook,
    public: true
  },
  // book about us
  {
    key: 'book-about',
    path: bookSeller.about,
    component: BookAboutUs,
    public: true
  },
  // journal about us
  {
    key: 'journal-about',
    path: appPaths.journal.about,
    component: JournalAboutUs,
    public: true
  },
  {
    key: 'question-answer',
    path: bookSeller.questionAnswer,
    component: BookQuestionAnswer,
    public: true
  },
  {
    key: 'term-of-service',
    path: bookSeller.termOfService,
    component: TermOfService,
    public: true
  },
  {
    key: 'specificel-commercial-transaction',
    path: bookSeller.specificelCommercialTransaction,
    component: SpecificelCommercialTransaction,
    public: true
  },
  {
    key: 'privacy-policy',
    path: bookSeller.privacyPolicy,
    component: PrivacyPolicy,
    public: true
  }
]

export const publicRoute = routes.filter((route) => route.public)
export const privateRoute = routes.filter((route) => !route.public)
