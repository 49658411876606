import { IAuth } from '@app/types/auth'
import { IResponseData } from '@app/types/common'
import { createSlice } from '@reduxjs/toolkit'
import StorageServices from '@services/local.storage'
import { authApi } from '@store/api/auth.api'

import store from '..'

const name = 'authSlice'

const initialState: IAuth = {
  token: StorageServices.getData('token', null),
  customers: {
    id: null,
    first_name: null,
    last_name: null,
    address: null,
    address_2: null,
    address_3: null,
    zip_code: null,
    type: '',
    avatar: null,
    birthday: null,
    gender: '',
    phone: null,
    email: '',
    receive_notification: 1,
    last_login_time: '',
    credit_card: {
      card_name: '',
      expiration_month: '',
      expiration_year: '',
      expired_date: '',
      card_number: '',
      security_code: ''
    }
  }
}

const authSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    logout: (state) => {
      state = initialState
      for (const key of Object.keys(localStorage).filter((x) => x !== 'cart')) {
        localStorage.removeItem(key)
      }
    },
    updateCustomer: (state, action) => {
      state.customers = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }: { payload: IResponseData<IAuth> }) => {
          StorageServices.setData('token', payload?.data?.token)
          return {
            ...state,
            token: payload?.data?.token,
            customers: payload?.data?.customers
          }
        }
      )
      .addMatcher(
        authApi.endpoints.verify.matchFulfilled,
        (state, { payload }: { payload: IResponseData<IAuth> }) => {
          StorageServices.setData('token', payload?.data?.token)
          return {
            ...state,
            token: payload?.data?.token,
            customers: payload?.data?.customers
          }
        }
      )
  }
})

export const { updateCustomer, logout } = authSlice.actions

export default authSlice.reducer
