import {
  ARTICLE_TYPE,
  BOOK_TYPE,
  BUY_NOW_CART,
  CART_KEY,
  CATEGORIES_BOOK,
  CATEGORIES_COLOR,
  GENDER,
  IS_BUY_NOW,
  NODATA,
  PAID_STATUS_CONVERT
} from '@app/config/const'
import { NotificationType } from '@app/types/common'
import StorageServices from '@services/local.storage'
import { CartProductItem } from '@store/api/cart.api'
import store from '@store/index'
import { setBuyNowCart } from '@store/slices/buynow.slice'
import { setCart } from '@store/slices/cart.slice'
import { notification } from 'antd'
import dayjs from 'dayjs'
import { isArray, isEmpty } from 'lodash'

/**
 * function check screen is mobile or desktop
 * @param width
 * @returns
 */
export const isMobile = (width: number) => width < 576

/**
 * function check screen is mobile or desktop
 * @param width
 * @returns
 */
export const isTablet = (width: number) => width < 991

/**
 * function show notification depend type
 * type: "success", "info", "warning", "error"
 * @param type
 * @param message
 */
export const showNotification = (type: NotificationType, message: string) => {
  notification.destroy()
  notification[type]({ message })
}

/**
 * function that support to get params from url
 * @param key
 * @returns
 */
export const getParamsFromURL = (key: string) =>
  new URLSearchParams(window.location.search).get(key)

/**
 * function that support to format number Ex: 1 to 01
 * @param d
 * @returns
 */
export const padFormat = (d: number | string) => {
  if (d.toString().length == 1) {
    return Number(d) < 10 ? `0${d.toString()}` : d.toString()
  }
  return d.toString()
}

/**
 * function that support to format number Ex: 1 to 01
 * @param gender
 * @returns
 */
export const jaConvertGender = (gender?: string): string => {
  if (!gender) {
    return '--'
  }
  switch (gender) {
    case 'male':
      return '男性'
    case 'female':
      return '女性'
    case 'other':
      return 'その他'
    default:
      return ''
  }
}

/**
 * function that support render gender
 * @param key
 * @param valueDefault
 * @returns
 */
export const getGender = (
  key: string | undefined | null,
  valueDefault: string = NODATA
) => {
  if (!key && typeof key !== 'string') return valueDefault

  if (key === 'male') return GENDER.MALE
  else if (key === 'female') return GENDER.FEMALE
  else return GENDER.OTHER
}

type Address = string | undefined | null
/**
 * function that
 * @param address
 * @param address_2
 * @param address_3
 * @returns
 */
export const getAddress = (
  prefecture: Address,
  address: Address,
  address_2: Address,
  address_3: Address
) => {
  const r = [
    prefecture?.trim(),
    address?.trim(),
    address_2?.trim(),
    address_3?.trim()
  ]
    .filter(Boolean)
    .join('')
  return r !== '' ? r : NODATA
}

/**
 * convert number to two length
 * @param d
 * @returns
 */
export const pad = (d: number | string) =>
  +d < 10 ? `0${d.toString()}` : d.toString()

/**
 * function that
 * @param category_id
 * @returns
 */
export const getArticleType = (category_id: number) => {
  switch (category_id) {
    case 1:
      return {
        name: ARTICLE_TYPE.MUSEUM.name,
        color: ARTICLE_TYPE.MUSEUM.color
      }
      break
    case 2:
      return {
        name: ARTICLE_TYPE.FORUM.name,
        color: ARTICLE_TYPE.FORUM.color
      }
      break
    case 3:
      return {
        name: ARTICLE_TYPE.ATRANDOM.name,
        color: ARTICLE_TYPE.ATRANDOM.color
      }
      break
    case 4:
      return {
        name: ARTICLE_TYPE.BOOKSELLER.name,
        color: ARTICLE_TYPE.BOOKSELLER.color
      }
      break
    default:
      return {
        name: ARTICLE_TYPE.MUSEUM.name,
        color: ARTICLE_TYPE.MUSEUM.color
      }
      break
  }
}

/**
 * function that
 * @param paid_status
 * @returns
 */
export const getArticleCategory = (paid_status: string) => {
  switch (paid_status) {
    case 'free':
      return PAID_STATUS_CONVERT.FREE
      break
    case 'paid':
      return PAID_STATUS_CONVERT.PAID
      break
    default:
      return PAID_STATUS_CONVERT.FREE
      break
  }
}

/**
 * function that
 * @param dateString
 * @returns
 */

export const getDateToShowConvert = (dateString: string) =>
  dateString.replace(/-/g, '.').replace('T', ' ')

/**
 * function that
 * @param dateString
 * @returns
 */

export const getDateToFormat = (dateString: string) => {
  const result = dateString.slice(0, 10).replaceAll('-', '.')
  return result
}

/**
 * function get label of category
 * @param type
 * @returns
 */
export const getTypeBook = (type: string | BOOK_TYPE = '1') =>
  CATEGORIES_BOOK.find((x) => x.id == type)?.label

/**
 * function get color of category
 * @param type
 * @returns
 */
export const getColorCategory = (type: string | BOOK_TYPE = '1') =>
  CATEGORIES_COLOR.find((x) => x.id == type)?.label

/**
 * get status of stock
 * @param stockStatus
 * @returns
 */
export const getStockStatus = (stockStatus: number) => {
  if (!stockStatus) return '在庫切れ'
  else return '在庫有り'
}
/**
 * @param dataString
 * @returns
 */
export const getDateJapaneseDay = (dateString: string) => {
  if (!dateString) {
    return ''
  }
  const dateObj = new Date(dateString)
  const year = dateObj.getFullYear()
  const month = dateObj.getMonth() + 1
  const day = dateObj.getDate()
  const resultString = `${year}年${month}月${day}日`
  return resultString
}
/**
 * @param dataString
 * @returns
 */
export const getDateJapaneseDayAndTime = (dateString: string) => {
  if (!dateString) {
    return ''
  }
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  const formattedDate = `${year}年${month}月${day}日 ${hours}:${minutes}`
  return formattedDate
}

/**
 * function check user login or not
 * @returns
 */
export const isLogin = () => {
  const token = StorageServices.getData('token', undefined)
  if (token) return true
  else return false
}

/**
 * add to cart without login
 * @param data
 * @returns
 */
export const addToCart = (data: CartProductItem) => {
  const cart = StorageServices.getData<CartProductItem[] | undefined>(
    CART_KEY,
    []
  )

  if (cart && cart.length > 0) {
    const index = cart.findIndex((item) => item.book_id === data.book_id)
    if (index > -1) {
      if (data.type === BOOK_TYPE.ACTUAL_BOOK) {
        console.log('dd', data.stock, cart[index].stock)

        if (Number(data.stock) < Number(cart[index].quantity + data.quantity)) {
          showNotification('error', '在庫切れ')
          return
        }
        cart[index].amount += data.amount
        cart[index].quantity += data.quantity
        StorageServices.setData(CART_KEY, cart)
        store.dispatch(setCart(cart))
        showNotification('success', 'カートに追加しました')
      } else {
        showNotification('info', 'この本は既にカートに入っています')
        return
      }
    } else {
      cart?.push(data)
      StorageServices.setData(CART_KEY, cart)
      store.dispatch(setCart(cart))
      showNotification('success', 'カートに追加しました')
    }
  } else {
    cart?.push(data)
    StorageServices.setData(CART_KEY, cart)
    store.dispatch(setCart(cart))
    showNotification('success', 'カートに追加しました')
  }
}

/**
 * delete cart item
 * @param book_id
 */
export const deleteCartItem = (book_id: number) => {
  const isBuyNow = StorageServices.getData(IS_BUY_NOW, undefined)
  const cart = StorageServices.getData<CartProductItem[] | undefined>(
    isBuyNow ? BUY_NOW_CART : CART_KEY,
    undefined
  )

  if (cart && cart.length > 0) {
    const index = cart.findIndex((item) => item?.book_id === book_id)

    if (index > -1) {
      cart.splice(index, 1)
      StorageServices.setData(isBuyNow ? BUY_NOW_CART : CART_KEY, cart)
      console.log('detele', cart)

      isBuyNow
        ? store.dispatch(setBuyNowCart(cart))
        : store.dispatch(setCart(cart))
      showNotification('success', 'カートの商品を削除しました')
    }
  } else {
    showNotification('error', 'カートに本は存在しません。')
  }
}

/**
 * update cart item
 * @param book_id
 * @param quantity
 * @param amount
 */
export const updateCardItem = (
  book_id: number,
  quantity: number,
  amount: number
) => {
  const isBuyNow = StorageServices.getData(IS_BUY_NOW, undefined)
  const cart = StorageServices.getData<CartProductItem[] | undefined>(
    isBuyNow ? BUY_NOW_CART : CART_KEY,
    undefined
  )

  if (cart && cart.length > 0) {
    const index = cart.findIndex((item) => item.book_id === book_id)
    if (index > -1) {
      if (quantity > cart[index]?.stock) {
        showNotification('error', '在庫切れ')
        return
      }
      cart[index].quantity = quantity
      cart[index].amount = amount
      if (!isBuyNow) {
        store.dispatch(setCart(cart))
        StorageServices.setData(CART_KEY, cart)
      } else {
        store.dispatch(setBuyNowCart(cart))
        StorageServices.setData(BUY_NOW_CART, cart)
      }
      showNotification('success', 'カートの更新に成功しました。')
    } else {
      showNotification('error', 'カートに本は存在しません。')
    }
  }
}

/**
 * allow input number
 * @param e
 */
export const blockLetterDecimal = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  const numbers = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'Backspace',
    'Delete',
    'ArrowLeft',
    'ArrowRight'
  ]
  if (!numbers.includes(e.nativeEvent.key.toString())) {
    e.preventDefault()
  }
}

/**
 * function support convert date
 * @param date
 * @param currentFormat
 * @param newFormat
 * @returns
 */
export const getFormatDate = (
  date: string | undefined,
  currentFormat: string,
  newFormat: string
) => (date ? dayjs(date, currentFormat).format(newFormat) : NODATA)

/**
 * function validate day
 * @param year
 * @param month
 * @param day
 * @returns
 */
export const validateDate = (year: string, month: string, day: string) => {
  console.log(year, month, day)

  // Validate year (any four digits)
  const yearPattern = /^\d{4}$/

  if (!yearPattern.test(year)) {
    return false
  }

  // Validate month (01 to 12)
  const monthPattern = /^(0?[1-9]|1[0-2])$/

  if (!monthPattern.test(month)) {
    return false
  }

  const thirtyOneDayPattern = /^(0?[1-9]|[12]\d|3[01])$/
  const thirtyDayPattern = /^(0?[1-9]|[12]\d|30)$/

  const monthNumber = parseInt(month, 10)
  const yearNumber = parseInt(year, 10)
  const dayNumber = parseInt(day, 10)

  if (monthNumber === 2) {
    // Check if it's a leap year
    if (
      (yearNumber % 4 === 0 && yearNumber % 100 !== 0) ||
      yearNumber % 400 === 0
    ) {
      // Leap year, February can have 29 days
      if (dayNumber > 29) {
        return false
      }
    } else {
      // Not a leap year, February can have 28 days
      if (dayNumber > 28) {
        return false
      }
    }
  } else if ([1, 3, 5, 7, 8, 10, 12].includes(monthNumber)) {
    if (!thirtyOneDayPattern.test(day)) {
      return false
    }
  } else {
    if (!thirtyDayPattern.test(day)) {
      return false
    }
  }

  return true
}

export interface INode {
  id: string
  depth?: number
  expanded?: boolean
  subitems?: INode[]
}

export function dfs<T extends INode>(node: T, fn: (node: T) => void) {
  fn(node)
  node.subitems?.forEach((child) => dfs(child as T, fn))
}

export function compareHref(
  sectionHref: string | undefined,
  navitemHref: string | undefined
) {
  if (sectionHref && navitemHref) {
    const [target] = navitemHref.split('#')

    return sectionHref.endsWith(target!) || target?.endsWith(sectionHref)
  }
}

export function flatTree<T extends INode>(node: T, depth = 1): T[] {
  if (!node.subitems || !node.subitems.length || !node.expanded) {
    return [{ ...node, depth }]
  }
  const children = node.subitems.flatMap((i) => flatTree(i, depth + 1)) as T[]
  return [{ ...node, depth }, ...children]
}

const handleTouchMove = (e: any) => e.preventDefault()
export const touchMoveOn = () =>
  document.addEventListener('touchmove', handleTouchMove, {
    passive: false
  })

export const touchMoveOff = () =>
  document.removeEventListener('touchmove', handleTouchMove)

export const formatMoney = (money: string | number) =>
  `${Number(money).toLocaleString('ja-JP', {
    style: 'currency',
    currency: 'JPY'
  })}`

export const formatNumber = (number: string | number) =>
  `${Number(number).toLocaleString('ja-JP')}`

/**
 * hide number
 * @param inputString
 * @returns
 */
export const convertToAsterisksExceptLastTwo = (inputString?: string) => {
  if (!inputString) return '--'
  const firstPart = inputString.slice(0, -2)
  const lastTwoDigits = inputString.slice(-2)

  const asteriskString = firstPart.replace(/\d/g, '*') + lastTwoDigits
  return asteriskString
}

/**
 * validate number and half width
 * @param input
 * @returns
 */
export function validateHalfWidthNumber(input: string) {
  const pattern = /^[0-9]+$/
  return pattern.test(input)
}

/**
 * get label
 * @param font
 * @returns
 */
export const getFontLable = (font: string) => {
  switch (font) {
    case 'source-han-serif-japanese':
      return '游明朝'
    case 'hiragino-mincho-pron':
      return 'ヒラギノ明朝'
    case 'hiragino-kaku-gothic-pron':
      return 'ヒラギノ角ゴ'
    default:
      return '游明朝'
  }
}

/**
 * detect touch screen
 * @returns
 */
export function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator?.maxTouchPoints > 0 ||
    (navigator as any)?.msMaxTouchPoints > 0
  )
}
/**
 * is production
 * @returns
 */
export const isProduction = () =>
  process.env.REACT_APP_NODE_ENV === 'production'

export const parserError = (data?: any) => {
  const errors = data?.errors
  if (!errors || isEmpty(errors)) {
    return data?.message
  }
  const messages = Object.values(errors)?.[0]
  if (isEmpty(messages) || !isArray(messages)) {
    return data?.message
  }
  return messages[0]
}
