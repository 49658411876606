import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from '../footer/Footer'

function HomePageJournalLayout() {
  return (
    <>
      <div className="home-page-journal-layout">
        <div className="home-page-journal-container">
          <Outlet />
        </div>
      </div>
      <div className="home-page-journal-footer">
        <Footer />
      </div>
    </>
  )
}

export default React.memo(HomePageJournalLayout)
