import { TOKEN_KEY } from '@app/config/const'
import { showNotification } from '@app/utils/helperFunction'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import StorageServices from '@services/local.storage'
import { Mutex } from 'async-mutex'

import store from '..'
interface CustomFetchArgs extends FetchArgs {
  isOptionAuth?: boolean
}
const clearSession = () => {
  localStorage.clear()
  sessionStorage.clear()
}

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000,
  prepareHeaders: (headers) => {
    const accessToken = StorageServices.getData(TOKEN_KEY, null)
    if (!accessToken) {
      store.dispatch(apiService.util.resetApiState())
      clearSession()
      window.location.href = '/journal/auth/login'
      return
    }
    headers.set('authorization', `Bearer ${accessToken}`)
    headers.set('Accept', 'application/json')
    headers.set('Accept-Language', 'jp')
    headers.set('content-language', 'ja-jps')
    return headers
  }
})
export const baseQueryOptionAuth = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000,
  prepareHeaders: (headers) => {
    const accessToken = StorageServices.getData(TOKEN_KEY, null)
    headers.set('authorization', `Bearer ${accessToken}`)
    headers.set('Accept', 'application/json')
    headers.set('Accept-Language', 'jp')
    headers.set('content-language', 'ja-jps')
    return headers
  }
})

const mutex = new Mutex()
const baseQueryWithReauth: BaseQueryFn<
  CustomFetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available swithout locking it
  await mutex.waitForUnlock()
  let result = (await (!args?.isOptionAuth
    ? baseQuery(args, api, extraOptions)
    : baseQueryOptionAuth(args, api, extraOptions))) as any

  /**
   * condition auto show error message when status 200 but have error
   */
  if (result?.error?.data?.status === false) {
    showNotification('error', (result?.error.data as any)?.message)
    return undefined
  }

  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery(
          '/refreshToken',
          api,
          extraOptions
        )
        if (refreshResult.data) {
          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          clearSession()
          window.location.replace('/sign-in')
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}

export const apiService = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: 'baseApi',
  tagTypes: [
    'baseApi',
    'bookmarkApi',
    'bookmarkHighLight',
    'cartApi',
    'membershipApi',
    'bookApi',
    'historyApi',
    'mybookApi'
  ],
  endpoints: () => ({})
})
