import { appPaths, authPaths, bookSeller } from '@app/router/paths'
import { isMobile } from '@app/utils/helperFunction'
import SearchInput from '@components/common/search-input/SearchInputJounal'
import Wrapper from '@components/epub/sideMenu/Wrapper'
import { JounalDashboardContext } from '@components/layout/app-layout/DashboardLayoutJounal'
import { useWindowWidth } from '@react-hook/window-size'
import { Input } from 'antd'
import { memo, useCallback, useContext, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

interface Props {
  isShowInputSearch: boolean
  isShowAuthLink: boolean
}
const Navigation = ({ isShowInputSearch, isShowAuthLink }: Props) => {
  const [search, setSearch] = useState({
    isOpen: false,
    keyword: ''
  })
  const contextJounal = useContext(JounalDashboardContext)
  const navigate = useNavigate()
  const width = useWindowWidth()
  const isMobileScreen = isMobile(width)
  const handleToggleMbSideBar = () => {
    if (Object.keys(contextJounal).length == 0) {
      navigate(appPaths.journal.accountSetting.accountInfomation)
    }
    contextJounal.sideBarLeft?.action((pre) => !pre)
  }
  const onSearch = useCallback(
    (values: string) => {
      const query = values && values.trim()
      if (location.pathname == appPaths.journal.topPage.museum) {
        navigate(`journal/search-result/museum/?q=${query}`)
      } else if (location.pathname == appPaths.journal.topPage.forum) {
        navigate(`journal/search-result/forum/?q=${query}`)
      } else {
        navigate(`journal/search-result/home/?q=${query}`)
      }
      setSearch({
        isOpen: false,
        keyword: ''
      })
    },
    [navigate, location]
  )
  const isShowNavSearch = useMemo(() => {
    if (isShowInputSearch) {
      if (!isShowAuthLink && !isMobileScreen) {
        return true
      } else if (isShowAuthLink) {
        return true
      }
    }
  }, [isShowInputSearch, isShowAuthLink, isMobileScreen])

  return (
    <>
      <div className={`${!isShowAuthLink && 'gap-20'} app-navigation`}>
        {isShowAuthLink ? (
          <>
            <Link
              to={authPaths.journal.register}
              className="nav-item"
            >
              会員登録
            </Link>
            <Link
              to={authPaths.journal.login}
              className="nav-item"
            >
              ログイン
            </Link>
          </>
        ) : (
          <>
            {!isMobileScreen ? (
              <>
                <img
                  src="/assets/png/book-seller-link.png"
                  alt="book-seller-link"
                  className="book-seller-link"
                  onClick={() => navigate(bookSeller.bookSeller)}
                />
                <div
                  className="nav-item my-page"
                  onClick={handleToggleMbSideBar}
                >
                  マイページ
                </div>
              </>
            ) : (
              <div className="dashboard-nav">
                <div className="item">
                  <img
                    src="/assets/svg/search-icon.svg"
                    alt="search-icon"
                    className="search-icon"
                    onClick={() =>
                      setSearch((pre) => ({ ...pre, isOpen: true }))
                    }
                  />
                  <img
                    src="/assets/svg/person-icon.svg"
                    alt="account-icon"
                    className="ml-20 w-24 h-24 search-icon"
                    onClick={handleToggleMbSideBar}
                  />
                </div>
                <div className="item mt-10">
                  <p
                    className="book-link"
                    onClick={() => {
                      navigate(bookSeller.bookSeller)
                    }}
                  >
                    現研ブックセラーへ
                  </p>
                </div>
              </div>
            )}
          </>
        )}
        {isShowNavSearch && (
          <div className="nav-search">
            <SearchInput
              placeholder="キーワードを入力してください"
              className="custom-search"
            />
          </div>
        )}
        {search && search.isOpen && (
          <Wrapper
            title="検索"
            show={search.isOpen}
            onClose={() => setSearch((pre) => ({ ...pre, isOpen: false }))}
            cusHeight={'20vh'}
          >
            <div className="search-text-box">
              <Input.Search
                className="input-search"
                onSearch={onSearch}
                autoFocus
                allowClear
                enterKeyHint="go"
              />
            </div>
          </Wrapper>
        )}
      </div>
    </>
  )
}

export default memo(Navigation)
