import { TOKEN_KEY } from '@app/config/const'
import { appPaths, bookSeller } from '@app/router/paths'
import { IAuth } from '@app/types/auth'
import { isMobile } from '@app/utils/helperFunction'
import LogoIcon from '@assets/svg/logoIcon'
import { useWindowWidth } from '@react-hook/window-size'
import StorageServices from '@services/local.storage'
import { useGetCartQuery } from '@store/api/cart.api'
import { useAppSelector } from '@store/index'
import { Button } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import Navigation from '../app-layout/nav/Navigation'

function Header() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const width = useWindowWidth()
  const isMobileScreen = isMobile(width)

  /**
   * get item off cart
   */
  const token = StorageServices.getData(TOKEN_KEY, undefined)
  const auth = useAppSelector((state) => state.auth) as IAuth
  console.log('getcart', token, auth)

  useGetCartQuery(undefined, {
    skip: !token || auth.customers.role === 2,
    refetchOnMountOrArgChange: true
  })

  return (
    <div
      className={`app-header flex justify-between${
        location.pathname.includes('/account-setting')
          ? ' profile-book-seller'
          : ''
      }`}
    >
      <div
        className="desktop-logo cursor-pointer"
        onClick={() => navigate(bookSeller.bookSeller)}
      >
        <LogoIcon />
      </div>
      <img
        className="mobile-logo"
        src="/assets/png/book-seller-logo-mobile.png"
        alt="app-logo"
        onClick={() => navigate(bookSeller.bookSeller)}
      />
      <Navigation />
      {isMobileScreen && pathname.includes('/account-setting') && (
        <div className="flex justify-end mt-12">
          <Link to={appPaths.journal.topPage.home}>
            <Button className="button-link-journal">現研ジャーナルへ</Button>
          </Link>
        </div>
      )}
    </div>
  )
}
export default Header
