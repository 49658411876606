// lib
import palette from '@assets/styles/epub/palette'
import * as styles from '@assets/styles/epub/styles'
// icons
import { CloseIcon } from '@assets/svg'
import styled from 'styled-components'

const CloseBtn = ({ onClick }: Props) => (
  <Btn onClick={onClick}>
    <Icon />
  </Btn>
)

const Btn = styled.button`
  position: relative;
  height: 100%;
  padding: 0 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s ${styles.transition};
  outline: none;
  border: unset;
  background: unset;

  &:focus,
  &:hover {
    color: ${palette.primary};
  }
`

const Icon = styled(CloseIcon)`
  width: 18px;
  height: 18px;
`

interface Props {
  onClick: () => void
}

export default CloseBtn
