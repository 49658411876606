import { appPaths, authPaths, bookSeller } from '@app/router/paths'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
interface Props {
  setMt0: (isSet: boolean) => void
}
export const listScreenHideSubHeader = [authPaths.journal.register]
const SubHeader = ({ setMt0 }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isShow = useMemo(() => {
    let result = false
    if (
      authPaths.journal.register != location.pathname &&
      appPaths.journal.freeMember.cancelSuccess != location.pathname &&
      appPaths.journal.about != location.pathname
    ) {
      setMt0(true)
      result = true
    } else {
      setMt0(false)
    }
    return result
  }, [location.pathname, setMt0])
  const activeTab = (path: string) => {
    if (location.pathname.includes(path)) {
      return true
    } else {
      return false
    }
  }
  if (!isShow) {
    return <></>
  }
  return (
    <div className="app-sub-header">
      <div className="section-nav">
        <div
          className={`nav-item ${
            activeTab(appPaths.journal.topPage.museum) && 'active'
          }`}
          onClick={() => navigate(appPaths.journal.topPage.museum)}
        >
          <img
            className={`image desktop`}
            src="/assets/png/museum-dk.png"
            alt=""
          />
          <img
            className="image mobile"
            src="/assets/png/street_icon-mb.png"
            alt=""
          />
          <span
            className={`title-mb ${
              activeTab(appPaths.journal.topPage.museum) && 'active'
            }`}
          >
            ミュージアム通り
          </span>
        </div>
        <div
          className={`nav-item ${
            activeTab(appPaths.journal.topPage.forum) && 'active forum'
          }`}
          onClick={() => navigate(appPaths.journal.topPage.forum)}
        >
          <img
            className={`image desktop `}
            src="/assets/png/forum-dk.png"
            alt=""
          />
          <img
            className="image mobile"
            src="/assets/png/forum_icon-mb.png"
            alt=""
          />
          <span
            className={`title-mb ${
              activeTab(appPaths.journal.topPage.forum) && 'active'
            }`}
          >
            フォーラム
          </span>
        </div>
        <div
          className="nav-item"
          onClick={() => navigate(bookSeller.bookSeller)}
        >
          <img
            className="image desktop"
            src="/assets/png/book-seller-dk.png"
            alt=""
          />
          <img
            className="image mobile"
            src="/assets/png/book_icon-mb.png"
            alt=""
          />
          <span className="title-mb">現研ブックセラー</span>
        </div>
      </div>
    </div>
  )
}

export default SubHeader
