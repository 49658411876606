import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { authApi } from './api/auth.api'
import { apiService } from './api/base'
import { bookReaderApi } from './api/book.reader.api'
import { customerApi } from './api/customer.api'
import auth from './slices/auth.slice'
import book from './slices/book.slice'
import buyNowCart from './slices/buynow.slice'
import cart from './slices/cart.slice'
import journalSlice from './slices/journal.slice'
import snackbar from './slices/snackbar.slice'

const persistConfigCustomer = {
  key: 'authSlice',
  storage,
  whitelist: ['customers']
}

const rootReducer = combineReducers({
  auth: persistReducer(persistConfigCustomer, auth),
  book,
  cart,
  snackbar,
  journalSlice,
  buyNowCart,
  [apiService.reducerPath]: apiService.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [bookReaderApi.reducerPath]: bookReaderApi.reducer
})

export default rootReducer
