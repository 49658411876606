// import styling
import 'antd/dist/reset.css'
import '@assets/styles/main.scss'

// import router
import { routes } from '@app/router'
import { bookSeller } from '@app/router/paths'
import PrivateRoute from '@app/router/PrivateRoute'
import PublicRoute from '@app/router/PublicRoute'
// import layout
import AppLayout from '@components/layout/app-layout/AppLayout'
import AppLayoutJounal from '@components/layout/app-layout/AppLayoutJounal'
import AppDashboardJounal from '@components/layout/app-layout/DashboardLayoutJounal'
import GenkenHomPageLayout from '@components/layout/app-layout/GenkenHomPageLayout'
import HomePageJounalLayout from '@components/layout/app-layout/HomePageJounalLayout'
import ReadBookLayout from '@components/layout/app-layout/ReadBookLayout'
import { useGetSettingQuery } from '@store/api/article.api'
import NotFound from '@views/not-found'
import { useEffect, useMemo } from 'react'
import ReactGA from 'react-ga'
import { Route, Routes, useLocation } from 'react-router-dom'

function App() {
  const location = useLocation()
  const { data: dataSetting } = useGetSettingQuery({})
  const getLayout = useMemo((): JSX.Element => {
    if (location.pathname.includes('journal')) {
      if (location.pathname.includes('journal/account-setting')) {
        return <AppDashboardJounal />
      }
      return <AppLayoutJounal />
    }
    if (location.pathname === '/') {
      return <HomePageJounalLayout />
    }
    if (location.pathname === bookSeller.bookSeller) {
      return <GenkenHomPageLayout />
    }

    if (
      location.pathname.includes('/read-book') ||
      location.pathname.includes('/limit-book')
    ) {
      return <ReadBookLayout />
    }
    return <AppLayout />
  }, [location])
  useEffect(() => {
    if (dataSetting?.genken_ga) {
      ReactGA.initialize(dataSetting.genken_ga)
      ReactGA.pageview(window.location.pathname)
    }
  }, [dataSetting])
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as any
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
    }
    document.getElementsByTagName('head')[0].appendChild(link)
    if (location.pathname == '/' || location.pathname.includes('journal')) {
      link.href = '/favicon_2.ico'
    } else {
      link.href = '/favicon.ico'
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname.includes('read-book')) {
      window.document.documentElement.classList.add(
        'block-swipe-touch-pad-to-back'
      )
    } else
      window.document.documentElement.classList.remove(
        'block-swipe-touch-pad-to-back'
      )
  }, [location])

  return (
    <Routes>
      <Route
        path="/"
        element={getLayout}
      >
        {routes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={
              route.public ? (
                <PublicRoute>
                  <route.component />
                </PublicRoute>
              ) : (
                <PrivateRoute>
                  <route.component />
                </PrivateRoute>
              )
            }
          />
        ))}
      </Route>
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  )
}

export default App
