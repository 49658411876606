export const authPaths = {
  bookSeller: {
    signIn: '/sign-in',
    signUp: '/sign-up',
    memberShip: '/membership',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    verifyEmail: '/verify-email',
    verifyNewEmail: '/account-verify-new-email',
    accountSetting: '/account-setting'
  },
  journal: {
    login: '/journal/auth/login',
    register: '/journal/auth/register',
    forgotPassword: '/journal/auth/forgot-password',
    resetPassword: '/journal/auth/reset-password',
    registerSuccess: '/journal/auth/register-success',
    verifyEmail: '/journal/verify-email',
    verifyNewEmail: '/journal/account-verify-new-email',
    paidMemberShip: '/journal/paid-member-ship',
    paymentMethod: '/journal/payment-method',
    paidMemberShipComplete: '/journal/paid-member-ship-complete',
    paidMemberShipRegistered: '/journal/paid-member-ship-registered'
  }
}

export const bookSeller = {
  recommendBook: '/recommend-book/:slug',
  publisher: '/publishers',
  publisherDetail: '/publisher/:slug',
  authors: '/authors',
  authorDetail: '/author/:slug',
  readBook: '/read-book/:bookId',
  viewBook: '/limit-book/:code',
  bookSeller: '/book-seller',
  categoryList: '/book-seller/category/:id',
  searchList: '/book-seller/search',
  bookDetail: '/book/:slug',
  cart: '/cart',
  seller: '/seller',
  sellerDetail: '/seller/:id',
  accountSetting: {
    paymentMethod: '/account-setting?tab=payment-method',
    paymentMethodInformation: '/account-setting?tab=payment-method-information',
    bookSelf: '/account-setting?tab=my-bookshelf',
    monthlyPaidMember: '/account-setting?tab=membership',
    registerMemberSuccess: '/account-setting?tab=registered-member-success',
    registerInformation: '/account-setting?tab=registration-info',
    editRegisterInformation: '/account-setting?tab=edit-registration-info',
    membership: '/account-setting?tab=membership',
    paymentHistory: '/account-setting?tab=payment-history',
    accountInformation: '/account-setting?tab=account-info',
    changeEmail: '/account-setting?tab=change-email',
    changePassword: '/account-setting?tab=change-password',
    cardInformation: '/account-setting?tab=credit-card-info',
    changeCreditCard: '/account-setting?tab=change-credit-card',
    withdrawalProcedure: '/account-setting?tab=withdrawal-procedure',
    cancelMembershipSuccess: '/account-setting?tab=cancel-membership-success'
  },
  cancelFreeMemberSuccess: '/cancel-free-member-success',
  about: '/about-us',
  questionAnswer: '/question-answer',
  termOfService: '/term-of-service',
  specificelCommercialTransaction: '/specificel-commercial-transaction',
  privacyPolicy: '/privacy-policy'
}

export const tabNameAccountSetting = {
  myBooks: 'my-books',
  accountInfomation: 'account-info',
  changeEmail: 'change-email',
  changeEmailSuccess: 'change-email-success',
  changePassword: 'change-password',
  changePasswordSuccess: 'change-password-success',
  registerInfomation: 'register-info',
  updateRegisterInfomation: 'update-register-info',
  creditCardInformation: 'credit-card-info',
  updateCreditCardInformation: 'update-credit-card-info',
  membership: 'membership',
  membershipPayment: 'membership-payment',
  membershipPaymentOtherCard: 'membership-payment-orther-card',
  paymentHistory: 'payment-history',
  withdrawalProcedure: 'withdrawal-procedure',
  paidMembershipSuccess: 'paid-membership-success',
  cancelMembershipSuccess: 'cancel-membership-success'
}

export const appPaths = {
  journal: {
    account: '/journal/account-setting',
    accountSetting: {
      myBooks: `/journal/account-setting?tab=${tabNameAccountSetting.myBooks}`,
      accountInfomation: `/journal/account-setting?tab=${tabNameAccountSetting.accountInfomation}`,
      changeEmail: `/journal/account-setting?tab=${tabNameAccountSetting.changeEmail}`,
      changeEmailSuccess: `/journal/account-setting?tab=${tabNameAccountSetting.changeEmailSuccess}`,
      changePassword: `/journal/account-setting?tab=${tabNameAccountSetting.changePassword}`,
      changePasswordSuccess: `/journal/account-setting?tab=${tabNameAccountSetting.changePasswordSuccess}`,
      registerInfomation: `/journal/account-setting?tab=${tabNameAccountSetting.registerInfomation}`,
      updateRegisterInfomation: `/journal/account-setting?tab=${tabNameAccountSetting.updateRegisterInfomation}`,
      creditCardInformation: `/journal/account-setting?tab=${tabNameAccountSetting.creditCardInformation}`,
      updateCreditCardInformation: `/journal/account-setting?tab=${tabNameAccountSetting.updateCreditCardInformation}`,
      membership: `/journal/account-setting?tab=${tabNameAccountSetting.membership}`,
      membershipPayment: `/journal/account-setting?tab=${tabNameAccountSetting.membershipPayment}`,
      membershipPaymentOtherCard: `/journal/account-setting?tab=${tabNameAccountSetting.membershipPaymentOtherCard}`,
      paymentHistory: `/journal/account-setting?tab=${tabNameAccountSetting.paymentHistory}`,
      withdrawalProcedure: `/journal/account-setting?tab=${tabNameAccountSetting.withdrawalProcedure}`,
      paidMembershipSuccess: `/journal/account-setting?tab=${tabNameAccountSetting.paidMembershipSuccess}`,
      cancelMembershipSuccess: `/journal/account-setting?tab=${tabNameAccountSetting.cancelMembershipSuccess}`
    },
    topPage: {
      home: '/',
      museum: '/journal/top-page',
      forum: '/journal/forum'
    },
    article: {
      detail: '/journal/article/:articleSlug'
    },
    author: {
      list: '/journal/authors',
      detail: '/journal/author-detail/:authorSlug'
    },
    editor: {
      list: '/journal/editors',
      detail: '/journal/editor-detail/:editorSlug'
    },
    search: {
      searchResult: '/journal/search-result/:categoryQuery/'
    },
    freeMember: {
      cancelSuccess: '/journal/cancel-free-member-success'
    },
    about: '/journal/about-us'
  }
}
