import { BOOK_TYPE } from '@app/config/const'
import { BookConfigResponse, IBookTags, PaymentResponse } from '@app/types/book'
import { IResponseData } from '@app/types/common'
import StorageServices from '@services/local.storage'

import { apiService } from './base'

type ListBook = Book[]
interface ListBookSameAuthor {
  author_name: string
  data: Book[]
}

export const bookApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    getBooks: build.query<Response<{ books: ListBook[] }>, undefined>({
      query: () => ({
        url: '/books/get-books',
        method: 'GET',
        isOptionAuth: true
      })
    }),
    getBook: build.query<
      Response<{
        book: Book
        images: string[]
        list_book_same_author: ListBookSameAuthor[]
        list_book_same_category: Book[]
        articles: ArticleBookSeller[]
        seller_name: string
        tags: any[]
        is_purchased: boolean
        myEbook: MyBookInterface | null
        publisher_name: string
        publisher_slug: string
        recommended_book: Book
      }>,
      { slug: string; code?: string }
    >({
      query: ({ slug, code }) => ({
        url: !code
          ? `/books/get-book-detail/${slug}`
          : `/books/get-book-detail-by-code/${code}`,
        method: 'GET',
        isOptionAuth: StorageServices.getData('token', undefined) ? false : true
      }),
      transformResponse: (res: any) => {
        if (res?.status) {
          return res
        } else {
          window.location.href = '/not-found'
        }
      },
      providesTags: ['bookApi']
    }),
    getTags: build.query({
      query: () => ({
        url: '/books/get-tags',
        method: 'GET',
        isOptionAuth: true
      }),
      transformResponse: (res: IResponseData<IBookTags>) =>
        res.status ? res.data : undefined
    }),
    getBookByCategory: build.query<
      Response<{ books: Book[]; pagination: PaginationRes }>,
      { categoryId: string | BOOK_TYPE; page: number; per_page?: number }
    >({
      query: ({ categoryId = 1, page = 1, per_page = 15 }) => ({
        url: `/books/get-list-book-by-category/${categoryId}?page=${page}&per_page=${per_page}`,
        method: 'GET',
        isOptionAuth: true
      })
    }),
    getBookByTag: build.query({
      query: (params) => ({
        url: '',
        method: '',
        params: {}
      })
    }),
    getBookConfig: build.query({
      query: () => ({
        url: '/books/get-config',
        method: 'GET',
        isOptionAuth: true
      }),
      transformResponse: (res: BookConfigResponse) => res.data.config
    }),
    searchBook: build.query<
      Response<{ books: Book[]; pagination: PaginationRes }>,
      { keyword: string; page: number }
    >({
      query: ({ page = 1, keyword = '' }) => ({
        url: `/books/search-book-by-name-or-author?keyword=${keyword}&page=${page}`,
        method: 'GET',
        isOptionAuth: true
      })
    }),
    searchBookByTag: build.query<
      Response<{ books: Book[]; pagination: PaginationRes }>,
      { title: string; page: number }
    >({
      query: ({ page = 1, title = '' }) => ({
        url: `/books/get-list-book-by-tag?title=${title}&page=${page}`,
        method: 'GET',
        isOptionAuth: true
      })
    }),
    orderBook: build.mutation<any, any>({
      query: (body) => ({
        url: '/books/order',
        method: 'POST',
        body,
        isOptionAuth: true
      }),
      invalidatesTags: ['cartApi', 'historyApi', 'mybookApi']
    }),

    getPaymentHistory: build.query({
      query: (params) => ({
        url: `/books/get-list-payment-history`,
        method: 'GET',
        params: params
      }),
      providesTags: ['historyApi'],
      transformResponse: (res: PaymentResponse) =>
        res.status ? res.data : undefined
    }),
    getMyBook: build.query({
      query: (params) => ({
        url: `/books/get-list-book-purchased/`,
        method: 'GET',
        params: params
      }),
      providesTags: ['mybookApi'],
      transformResponse: (res: MyBookResponse) =>
        res.status ? res.data : undefined
    }),
    getInvoice: build.query({
      query: (params) => ({
        url: `/books/export-invoice`,
        method: 'GET',
        params: params
      })
    }),
    // Get special data for admin account
    getSpecialData: build.mutation({
      query: (data) => ({
        url: `/books/add-ebook-for-special-customer`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['bookApi']
    })
  })
})

export const {
  useGetBooksQuery,
  useGetBookQuery,
  useGetBookByCategoryQuery,
  useSearchBookQuery,
  useGetTagsQuery,
  useOrderBookMutation,
  useGetPaymentHistoryQuery,
  useGetMyBookQuery,
  useGetBookConfigQuery,
  useLazyGetInvoiceQuery,
  useGetSpecialDataMutation,
  useSearchBookByTagQuery
} = bookApi
