import { BUY_NOW_CART } from '@app/config/const'
import { createSlice } from '@reduxjs/toolkit'
import StorageServices from '@services/local.storage'
import { CartProductItem } from '@store/api/cart.api'

const initialState = StorageServices.getData<CartProductItem[]>(
  BUY_NOW_CART,
  []
)

const buyNowCartSlice = createSlice({
  name: 'buy_now_cart',
  initialState,
  reducers: {
    setBuyNowCart: (_, action) => {
      StorageServices.setData(BUY_NOW_CART, action.payload)
      return action.payload
    }
  }
})

export const { setBuyNowCart } = buyNowCartSlice.actions

export default buyNowCartSlice.reducer
