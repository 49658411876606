import { CART_KEY } from '@app/config/const'
import { createSlice } from '@reduxjs/toolkit'
import StorageServices from '@services/local.storage'
import { cartApi, CartProductItem } from '@store/api/cart.api'

const initialState = StorageServices.getData<CartProductItem[]>(CART_KEY, [])

console.log('initialState', initialState)

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (_, action) => {
      StorageServices.setData(CART_KEY, action.payload)
      return action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      cartApi.endpoints.getCart.matchFulfilled,
      (state, { payload }) => payload?.data?.carts ?? []
    )
  }
})

export const { setCart } = cartSlice.actions

export default cartSlice.reducer
