// components
import palette from '@assets/styles/epub/palette'
// lib
import * as styles from '@assets/styles/epub/styles'
import zIndex from '@assets/styles/epub/zIndex'
import CloseBtn from '@components/epub/sideMenu/CloseBtn'
import React from 'react'
import styled from 'styled-components'

const Wrapper = (
  { title, show, onClose, children, cusHeight }: Props,
  ref: any
) => (
  <Container
    show={show}
    style={{ height: cusHeight }}
    ref={ref}
  >
    <Header>
      <span>{title}</span>
      <CloseBtn onClick={onClose} />
    </Header>
    {children}
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 340px;
  max-width: 95vw;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: ${zIndex.menu};
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.16);
  background-color: ${palette.white};
  border-radius: 16px 0 0 16px;
  transform: ${({ show }: { show: boolean }) =>
    show ? `translateX(0px) scale(1)` : `translateX(420px) scale(.9)`};
  transition: 0.4s ${styles.transition};
  overflow-y: auto;
  ${styles.scrollbar(0)};

  .search-text-box {
    padding: 12px;
  }

  .result-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 24px 0;
    padding: 0 12px;
  }
`

const Header = styled.div`
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 3;

  & > span {
    padding-left: 24px;
    font-size: 21px;
    font-weight: 600;
  }
`

interface Props {
  title: string
  show: boolean
  onClose: () => void
  children?: React.ReactNode
  cusHeight?: string
}

export default React.forwardRef(Wrapper)
