import { Button, Result } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default React.memo(function PageNotFound() {
  const navigate = useNavigate()

  const backToHome = () => {
    navigate('/book-seller')
  }
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Result
        icon={<></>}
        title="404"
        subTitle="申し訳ありませんが、ご訪問いただいたページは存在しません。"
        extra={
          <Button
            type="primary"
            onClick={backToHome}
          >
            トップページへ戻る
          </Button>
        }
      />
    </div>
  )
})
