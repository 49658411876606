import './style.scss'

import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

import Footer from '../footer/Footer'
import Header from '../header-jounal/Header'
import SubHeader from '../header-jounal/SubHeader'

const AuthLayout = () => {
  const [mt0, setMt0] = useState(false)
  return (
    <div className="app-jounal">
      <div className="app-layout">
        <div className="header-wrap">
          <Header />
        </div>
        <SubHeader setMt0={(isSet) => setMt0(isSet)} />
        <div className={`app-content ${mt0 ? 'mt-0' : ''}`}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default React.memo(AuthLayout)
