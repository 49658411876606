import { BUY_NOW_CART, IS_BUY_NOW, TOKEN_KEY } from '@app/config/const'
import { appPaths, authPaths, bookSeller } from '@app/router/paths'
import { IAuth } from '@app/types/auth'
import { isMobile } from '@app/utils/helperFunction'
import AccountMenu from '@components/common/account-menu/AccountMenu'
import SearchInput from '@components/common/search-input/SearchInput'
import Wrapper from '@components/epub/sideMenu/Wrapper'
import { useWindowWidth } from '@react-hook/window-size'
import StorageServices from '@services/local.storage'
import { CartProductItem } from '@store/api/cart.api'
import { useAppSelector } from '@store/index'
import { Badge, Drawer, Input } from 'antd'
import { useCallback, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function Navigation() {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAppSelector((state) => state.auth) as IAuth
  const [openDrawer, setOpenDrawer] = useState(false)
  const data = useAppSelector((state) => state.cart) as CartProductItem[]
  const [search, setSearch] = useState({
    isOpen: false,
    keyword: ''
  })
  const closeDrawer = useCallback(() => {
    setOpenDrawer(false)
  }, [])

  const width = useWindowWidth()
  const isMobileScreen = isMobile(width)

  const onSearch = useCallback(
    (values: string) => {
      const query = values && values.trim()
      query && navigate(`${bookSeller.searchList}?q=${query}`)
      setSearch((pre) => ({ ...pre, isOpen: false }))
    },
    [navigate]
  )

  const getToken = StorageServices.getData(TOKEN_KEY, undefined)

  return (
    <>
      <div className="app-navigation">
        {location.pathname.includes('/account-setting') && !isMobileScreen && (
          <>
            <Link to={appPaths.journal.topPage.home}>
              <span className="group-journal-icon">
                <img
                  src="/assets/png/journal-icon.png"
                  className="bird-icon"
                />
                <img
                  src="/assets/svg/journal-home-page-heading.svg"
                  className="bird-slogan-icon"
                />
              </span>
            </Link>
          </>
        )}
        {!getToken && (
          <span
            className="nav-item nav-string border-btn"
            onClick={() => navigate(authPaths.bookSeller.signIn)}
          >
            ログイン
          </span>
        )}
        <span
          className="nav-item nav-string my-page-btn"
          onClick={() => navigate(`${authPaths.bookSeller.accountSetting}`)}
        >
          {location.pathname.includes('/account-setting')
            ? 'マイページ'
            : 'マイ本棚'}
        </span>
        <SearchInput />
        <div
          className="nav-icon-item"
          onClick={() => setSearch((pre) => ({ ...pre, isOpen: true }))}
        >
          <img
            src="/assets/svg/search-icon.svg"
            alt="search-icon"
            className="cart-icon"
          />
        </div>
        <div className="user-icon">
          <img
            onClick={() => {
              if (getToken) {
                setOpenDrawer(true)
              } else {
                navigate(authPaths.bookSeller.signIn)
              }
            }}
            src="/assets/svg/user-icon.svg"
            alt="user-icon"
            className="cart-icon nav-item"
          />
        </div>
        {auth.customers.role !== 2 && (
          <div
            onClick={() => {
              StorageServices.removeData(IS_BUY_NOW)
              StorageServices.removeData(BUY_NOW_CART)
              navigate(bookSeller.cart)
            }}
          >
            <Badge
              size={isMobileScreen ? 'small' : 'default'}
              count={data ? data.length : 0}
            >
              <img
                src="/assets/svg/cart-icon.svg"
                alt="cart-icon"
                className="cart-icon cart-badge nav-item"
              />
            </Badge>
          </div>
        )}
      </div>
      <Drawer
        headerStyle={{ display: 'none' }}
        className="account-setting-drawer"
        width="100vw"
        bodyStyle={{
          background: 'transparent',
          paddingRight: 0,
          display: 'flex',
          justifyContent: 'flex-end',
          overflowY: 'auto'
        }}
        maskStyle={{
          backgroundColor: '#000000',
          opacity: 0.6
        }}
        open={openDrawer}
      >
        <div className="account-setting-menu">
          <div className="flex justify-end pr-25 pt-14 pb-14">
            <img
              src="/assets/svg/close-icon.svg"
              onClick={() => setOpenDrawer(false)}
              alt="close-icon"
            />
          </div>
          <AccountMenu closeDrawer={closeDrawer} />
        </div>
      </Drawer>
      {search && search.isOpen && (
        <Wrapper
          title="検索"
          show={search.isOpen}
          onClose={() => setSearch((pre) => ({ ...pre, isOpen: false }))}
          cusHeight={'20vh'}
        >
          <div className="search-text-box">
            <Input.Search
              className="input-search"
              onSearch={onSearch}
              allowClear
              enterKeyHint="go"
            />
          </div>
        </Wrapper>
      )}
    </>
  )
}

export default Navigation
