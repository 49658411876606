import './style.scss'

import Footer from '@components/layout/footer/Footer'
import Header from '@components/layout/header-jounal/Header'
import { createContext, Dispatch, SetStateAction, useState } from 'react'
import { Outlet } from 'react-router-dom'
interface IContext {
  sideBarLeft?: {
    action: Dispatch<SetStateAction<boolean>>
    status: boolean
  }
}
export const JounalDashboardContext = createContext<IContext>({})
const Dashboard = () => {
  const [mbShowSideBar, setMbShowSideBar] = useState(false)
  const value = {
    sideBarLeft: {
      action: setMbShowSideBar,
      status: mbShowSideBar
    }
  }
  console.log('mbShowSideBar', mbShowSideBar)

  return (
    <JounalDashboardContext.Provider value={value}>
      <div className="app-jounal">
        <div className="app-layout">
          <div className="header-wrap">
            <Header />
          </div>
          <div className="app-content mt-120">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </JounalDashboardContext.Provider>
  )
}

export default Dashboard
