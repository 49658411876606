import { IResponseData } from '@app/types/common'
import { IHightLightResponse } from '@app/types/highlight'
import { CurrentLocation, CurrentLocationResponse } from '@app/types/page'
import { BookMarkResponse } from '@store/slices/book.slice'

import { apiService } from './base'

export const bookReaderApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    addBookMark: build.mutation({
      query: (body) => ({
        url: `/books/add-bookmark`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['bookmarkApi']
    }),
    // api get book mark
    deleteBookMark: build.mutation({
      query: (bookmarkId) => ({
        url: `/books/delete-bookmark/${bookmarkId}`,
        method: 'POST'
      }),
      invalidatesTags: ['bookmarkApi']
    }),
    // api get bookmark of book
    getMyBookmark: build.query({
      query: (bookId) => ({
        url: `/books/get-bookmarks/${bookId}`,
        method: 'GET'
      }),
      transformResponse: (res: BookMarkResponse) =>
        res.status ? res.data : undefined,
      providesTags: ['bookmarkApi']
    }),
    // Add high light
    addHighLight: build.mutation({
      query: (body) => ({
        url: `/books/add-highlight`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['bookmarkHighLight']
    }),
    // api delete high light
    deleteHighLight: build.mutation({
      query: (highLightId) => ({
        url: `/books/delete-highlight/${highLightId}`,
        method: 'POST'
      }),
      invalidatesTags: ['bookmarkHighLight']
    }),
    // api get high light
    getHighLight: build.query({
      query: (bookId) => ({
        url: `/books/get-highlights/${bookId}`,
        method: 'GET'
      }),
      transformResponse: (res: IHightLightResponse) =>
        res.status ? res.data : undefined,
      providesTags: ['bookmarkHighLight']
    }),
    // Add comment
    addComment: build.mutation({
      query: (body) => ({
        url: `/books/add-comment`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['bookmarkHighLight']
    }),
    // api delete high light
    deleteComment: build.mutation({
      query: (commentId) => ({
        url: `/books/delete-comment/${commentId}`,
        method: 'POST'
      }),
      invalidatesTags: ['bookmarkHighLight']
    }),

    // Save location
    saveLocation: build.mutation({
      query: (body) => ({
        url: `/books/save-current-location`,
        method: 'POST',
        body: body
      }),
      transformResponse: (
        res: IResponseData<{ currentLocation: CurrentLocation }>
      ) => (res.status ? res.data : undefined)
    }),
    // Save location
    getCurrentLocation: build.query({
      query: (bookId) => ({
        url: `/books/get-current-location/${bookId}`,
        method: 'GET'
      }),
      transformResponse: (res: CurrentLocationResponse) =>
        res.status ? res.data : undefined
    })
  })
})

export const {
  useAddBookMarkMutation,
  useAddCommentMutation,
  useAddHighLightMutation,
  useDeleteBookMarkMutation,
  useDeleteCommentMutation,
  useDeleteHighLightMutation,
  useSaveLocationMutation,
  useGetCurrentLocationQuery,
  useGetHighLightQuery,
  useGetMyBookmarkQuery,
  useLazyGetCurrentLocationQuery,
  useLazyGetHighLightQuery,
  useLazyGetMyBookmarkQuery
} = bookReaderApi
