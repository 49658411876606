import {
  HistoryMembershipResponse,
  IChangeEmail,
  IChangePassword,
  IUpdateCustomerCreditCardPayload,
  IUpdateCustomerProfilePayload,
  PrefectureResponse,
  ResponseCustomProfileInterface
} from '@app/types/customer'
import { updateCustomer } from '@store/slices/auth.slice'

import store from '..'
import { apiService } from './base'

export const customerApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query({
      query: () => ({
        url: '/customers/profile',
        method: 'GET'
      }),
      transformResponse: (
        baseQueryReturnValue: ResponseCustomProfileInterface
      ) => {
        const birthDay = baseQueryReturnValue?.data?.customers?.birthday
          ? new Date(String(baseQueryReturnValue.data.customers.birthday))
          : undefined
        store.dispatch(updateCustomer(baseQueryReturnValue.data.customers))

        return {
          ...baseQueryReturnValue.data.customers,
          birth_year: birthDay ? new Date(birthDay).getFullYear() : undefined,
          birth_month: birthDay ? new Date(birthDay).getMonth() + 1 : undefined,
          birth_day: birthDay ? new Date(birthDay).getDate() : undefined,
          prefecture_id:
            baseQueryReturnValue.data.customers?.prefecture?.id ?? ''
        }
      },
      providesTags: ['baseApi']
    }),
    changePassword: build.mutation({
      query: (data: IChangePassword) => ({
        url: '/customers/change-password',
        method: 'PUT',
        body: data
      })
    }),
    changeEmail: build.mutation({
      query: (data: IChangeEmail) => ({
        url: '/customers/change-email',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['baseApi']
    }),
    updateProfile: build.mutation({
      query: (data: IUpdateCustomerProfilePayload) => ({
        url: '/customers/update-profile',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['baseApi']
    }),
    updateCreditCard: build.mutation({
      query: (data: IUpdateCustomerCreditCardPayload) => ({
        url: '/customers/update-credit-card',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['baseApi']
    }),
    registerMemberShip: build.mutation({
      query: (data: IUpdateCustomerCreditCardPayload) => ({
        url: '/customers/update-membership',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['baseApi', 'membershipApi']
    }),
    cancelMemberShip: build.mutation({
      query: () => ({
        url: '/customers/cancel-membership',
        method: 'PUT'
      }),
      invalidatesTags: ['baseApi']
    }),
    getPrefectures: build.query({
      query: () => ({
        url: '/prefectures',
        method: 'GET'
      }),
      transformResponse: (res: PrefectureResponse) =>
        res.status ? res.data.prefectures : undefined
    }),
    getHistoryMembership: build.query({
      query: (params: { per_page?: number; page?: number }) => ({
        url: '/customers/history-membership',
        params,
        method: 'GET'
      }),
      providesTags: ['membershipApi', 'cartApi'],
      transformResponse: (res: HistoryMembershipResponse) => res.data
    }),
    cancelFreeMember: build.mutation({
      query: () => ({
        url: '/customers/cancel-account',
        method: 'PUT'
      })
    })
  })
})

export const {
  useGetProfileQuery,
  useChangePasswordMutation,
  useUpdateProfileMutation,
  useChangeEmailMutation,
  useUpdateCreditCardMutation,
  useRegisterMemberShipMutation,
  useCancelMemberShipMutation,
  useGetPrefecturesQuery,
  useGetHistoryMembershipQuery,
  useCancelFreeMemberMutation
} = customerApi
