import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'

const LoadContent = () => (
  <div
    className="flex justify-center items-center"
    style={{ height: 'calc(100vh - 78px)' }}
  >
    <LoadingOutlined className="text-dark-blue text-30" />
  </div>
)

export default React.memo(LoadContent)
