import { apiService } from './base'

export interface CartProductItem {
  id: number
  book_id: number
  price: string
  amount: number
  book_name: string
  quantity: number
  type?: number
  feature_image: string
  stock: number
  shipping_fee: number
  slug?: string
}

export const cartApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    addBookToCart: build.mutation<any, { book_id: number; quantity: number }>({
      query: (body) => ({
        url: '/books/cart/add-to-cart',
        method: 'POST',
        body
      }),
      invalidatesTags: ['cartApi']
    }),
    mergedCard: build.mutation<
      any,
      { carts: { book_id: number; quantity: number }[] }
    >({
      query: (body) => ({
        url: '/books/cart/merge-cart',
        method: 'POST',
        body
      }),
      invalidatesTags: ['cartApi']
    }),
    updateQuantityCart: build.mutation<
      any,
      { book_id: number; quantity: number }
    >({
      query: (body) => ({
        url: '/books/cart/update-quantity',
        method: 'POST',
        body
      }),
      invalidatesTags: ['cartApi']
    }),
    deleteProductFromCart: build.mutation<any, { book_id: number }>({
      query: ({ book_id }) => ({
        url: `/books/cart/delete-cart-item/${book_id}`,
        method: 'POST'
      }),
      invalidatesTags: ['cartApi']
    }),
    getCart: build.query<
      Response<{
        carts: CartProductItem[]
      }>,
      any
    >({
      query: () => ({
        url: '/books/cart/get-cart',
        method: 'GET'
      }),
      providesTags: ['cartApi']
    })
  })
})

export const {
  useAddBookToCartMutation,
  useGetCartQuery,
  useUpdateQuantityCartMutation,
  useDeleteProductFromCartMutation,
  useLazyGetCartQuery,
  useMergedCardMutation
} = cartApi
