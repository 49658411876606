// types
import Book from '@app/types/book'
import { IResponseData } from '@app/types/common'
import { Color, IHighLight } from '@app/types/highlight'
import Page from '@app/types/page'
import Toc from '@app/types/toc'
// lib
import palette from '@assets/styles/epub/palette'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import StorageServices from '@services/local.storage'
import { bookApi } from '@store/api/book.api'
import { bookReaderApi } from '@store/api/book.reader.api'

/* 
	Initial State
*/

const initialBook: Book = {
  coverURL: '',
  title: '',
  description: '',
  published_date: '',
  modified_date: '',
  author: '',
  publisher: '',
  language: ''
}

const initialCurrentLocation: Page = {
  chapterName: '-',
  currentPage: 0,
  totalPage: 0,
  startCfi: '',
  endCfi: '',
  base: '',
  isBookmark: false,
  percentage: 0,
  endPage: 1
}

const initialColorList: Color[] = [
  { name: 'Red', code: palette.red4 },
  { name: 'Orange', code: palette.orange4 },
  { name: 'Yellow', code: palette.yellow4 },
  { name: 'Green', code: palette.green4 },
  { name: 'Blue', code: palette.blue4 },
  { name: 'Purple', code: palette.purple4 }
]

const initialState: BookState = {
  book: initialBook,
  currentLocation: initialCurrentLocation,
  toc: [],
  highlights: [],
  colorList: initialColorList,
  bookmarks: [],
  bookmarkKeys: {},
  recommended_book: {},
  list_book_same_series: [],
  sections: [],
  bookId: null,
  next_chapter: null,
  isCalculating: false,
  bookStatus: { atStart: false, atEnd: false },
  bookDirection: 'rtl',
  background: StorageServices.getData('background', '#FFFFFF')
}

/* 
	Slice
*/
const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    /** Book  @dispatch */
    updateBook(state, action: PayloadAction<Book>) {
      state.book = action.payload
    },
    /** CurrentPage  @dispatch */
    updateCurrentPage(state, action: PayloadAction<Page>) {
      state.currentLocation = action.payload
    },
    /** clear book @dispatch */
    clearBook(state) {
      state.book = initialBook
    },
    /** update list content @dispatch */
    updateToc(state, action: PayloadAction<Toc[]>) {
      state.toc = action.payload
    },
    /**  @dispatch */
    clearToc(state) {
      state.toc = []
    },
    updateTotalBook(state, action) {
      let percentage = Math.floor(
        (action.payload.currentPage / action.payload.totalPage) * 100
      )
      percentage = percentage > 100 ? 100 : percentage
      const currentLocation_ = {
        ...state.currentLocation,
        percentage: percentage,
        totalPage: action.payload.totalPage,
        currentPage: action.payload.currentPage
      }
      state.currentLocation = currentLocation_
    },
    updateCalculateState(state, action) {
      state.isCalculating = action.payload
    },
    upadteBackground(state, action) {
      state.background = action.payload
    },
    updateBookStatus(state, action) {
      state.bookStatus = action.payload
    },
    updateBookDirection(state, action) {
      state.bookDirection = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        bookReaderApi.endpoints.saveLocation.matchFulfilled,
        (state, { payload }) => {
          if (payload && payload?.currentLocation) {
            console.log('payload.currentLocatio0n', payload.currentLocation)

            const {
              chapter_name = '',
              current_page = 0,
              total_page = 0,
              start_cfi = '',
              end_cfi = '',
              end_page = 1,
              percentage = 0
            } = payload.currentLocation
            state.currentLocation = {
              chapterName: chapter_name,
              currentPage: current_page,
              totalPage: total_page,
              startCfi: start_cfi,
              endCfi: end_cfi,
              base: initialCurrentLocation.base,
              endPage: end_page,
              isBookmark: false,
              percentage
            }
          }
        }
      )
      .addMatcher(
        bookReaderApi.endpoints.getHighLight.matchFulfilled,
        (state, { payload }) => {
          state.highlights = payload?.highlights ?? []
        }
      )
      .addMatcher(
        bookReaderApi.endpoints.getMyBookmark.matchFulfilled,
        (state, { payload }) => {
          const newBookKeys: { [key: string]: number } = {}
          payload?.bookmarks?.forEach((b) => {
            newBookKeys[b.start_cfi] = b.page
          })
          state.bookmarkKeys = newBookKeys
          state.bookmarks = payload?.bookmarks ?? []
        }
      )
      .addMatcher(
        bookReaderApi.endpoints.getCurrentLocation.matchFulfilled,
        (state, { payload }) => {
          if (payload && payload?.currentLocation) {
            const {
              chapter_name = '',
              current_page = 0,
              total_page = 0,
              start_cfi = '',
              end_cfi = '',
              percentage = 0,
              end_page = 1
            } = payload.currentLocation
            state.currentLocation = {
              chapterName: chapter_name,
              currentPage: current_page,
              totalPage: total_page,
              startCfi: start_cfi,
              endCfi: end_cfi,
              base: initialCurrentLocation.base,
              isBookmark: false,
              endPage: end_page,
              percentage
            }
          }
        }
      )
      .addMatcher(
        bookApi.endpoints.getBook.matchFulfilled,
        (state, { payload }) => {
          const pathname = window.location.pathname
          if (pathname.includes('read-book')) {
            state.bookId = payload?.data?.book?.id
            state.next_chapter = payload?.data?.book?.next_chapter ?? {}
            state.list_book_same_series =
              payload?.data?.book?.list_book_same_series ?? []
            state.recommended_book = payload?.data?.recommended_book
          }
        }
      )
  }
})

export interface IComment {
  id: string
  commentTime: string
  content: string
}

export interface BookMarkInterface {
  created_at: string
  id: number
  my_ebook_id: number
  page: number
  start_cfi: string
  title: string
  updated_at: string
  percentage: number
  total_page?: number
}

export type BookMarkResponse = IResponseData<{ bookmarks: BookMarkInterface[] }>

export interface BookState {
  book: Book
  currentLocation: Page
  toc: Toc[]
  highlights: IHighLight[]
  colorList: Color[]
  bookmarks: BookMarkInterface[]
  bookmarkKeys: { [key: string]: number }
  list_book_same_series: Series[]
  recommended_book: any
  bookId: number | null
  next_chapter: NextChapter | null
  sections: any[]
  isCalculating: boolean
  background: string
  bookStatus: { atEnd: boolean; atStart: boolean }
  bookDirection: 'rtl' | 'ltr'
}

export const {
  updateBook,
  clearBook,
  updateCurrentPage,
  updateToc,
  clearToc,
  updateTotalBook,
  updateCalculateState,
  upadteBackground,
  updateBookStatus,
  updateBookDirection
} = bookSlice.actions

export default bookSlice.reducer
