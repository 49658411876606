import './style.scss'

import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from '../footer/Footer'
import Header from '../header/Header'

const AuthLayout = () => (
  <div className="app">
    <div className="app-layout">
      <Header />
      <div className="app-content">
        <Outlet />
      </div>
    </div>
    <Footer />
  </div>
)

export default React.memo(AuthLayout)
