import { appPaths, bookSeller } from '@app/router/paths'
import { isProduction } from '@app/utils/helperFunction'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  const isLayoutJounal = useMemo(() => {
    const result =
      location.pathname.includes('journal') || location.pathname == '/'
    return result
  }, [location.pathname])
  const links = useMemo(() => {
    if (isLayoutJounal) {
      return [
        { label: '運営会社', url: 'https://www.gen-ken.co.jp/index.html' },
        { label: '利用規約', url: bookSeller.termOfService },
        { label: 'このサイトについて', url: appPaths.journal.about },
        { label: 'よくある質問', url: bookSeller.questionAnswer },
        {
          label: '特定商取引法に基づく表示',
          url: bookSeller.specificelCommercialTransaction
        },
        { label: 'プライバシーポリシー', url: bookSeller.privacyPolicy }
      ]
    } else {
      return [
        { label: '運営会社', url: 'https://www.gen-ken.co.jp/index.html' },
        { label: '利用規約', url: bookSeller.termOfService },
        { label: 'このサイトについて', url: bookSeller.about },
        { label: 'よくある質問', url: bookSeller.questionAnswer },
        {
          label: '特定商取引法に基づく表示',
          url: bookSeller.specificelCommercialTransaction
        },
        { label: 'プライバシーポリシー', url: bookSeller.privacyPolicy }
      ]
    }
  }, [isLayoutJounal])
  return (
    <div
      className="app-footer"
      style={{ position: 'relative' }}
    >
      <div className="footer-title flex justify-between max-w-1180 mx-auto leading-20 mt-30 text-15">
        {links.map((x, index) => (
          <Link
            key={`footer-item-${index}`}
            to={x.url}
            className="decoration-none"
            style={{ color: '#000000' }}
          >
            {x.label}
          </Link>
        ))}
      </div>
      <div className="text-center mt-19 text-17 copyright">
        株式会社現代経営技術研究所
      </div>
      {!isProduction() && (
        <div className="version text-center text-15">
          Version: 0.1.52 - 04/17/2024 - VN
        </div>
      )}
    </div>
  )
}
export default Footer
