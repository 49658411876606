import {
  IForgotPasswordData,
  ILoginData,
  IRegisterData,
  IResetPasswordData,
  IVerifyData,
  IVerifyNewEmailData
} from '@app/types/auth'
import { parserError, showNotification } from '@app/utils/helperFunction'
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react'

const fetchBaseQueryBasic = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    return headers
  }
})

const baseQueryAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await fetchBaseQueryBasic(args, api, extraOptions)

  if (result.error) {
    switch (result.error.status) {
      case 422: {
        const dataErrors = result?.error?.data as any
        const message = parserError(dataErrors)
        showNotification('error', message)
        break
      }
      default:
        break
    }
  }
  return result
}

export const authApi = createApi({
  baseQuery: baseQueryAuth,
  reducerPath: 'authApi',
  endpoints: (build) => ({
    login: build.mutation({
      query: (data: ILoginData) => ({
        url: '/customers/login',
        method: 'POST',
        body: data
      })
    }),
    register: build.mutation({
      query: (data: IRegisterData) => ({
        url: '/customers/register',
        method: 'POST',
        body: data
      })
    }),
    forgotPassword: build.mutation({
      query: (data: IForgotPasswordData) => ({
        url: '/customers/forgot-password',
        method: 'POST',
        body: data
      })
    }),
    resetPassword: build.mutation({
      query: (data: IResetPasswordData) => ({
        url: '/customers/reset-password',
        method: 'POST',
        body: data,
        redirect: 'follow',
        headers: { Accept: 'application/json' }
      })
    }),
    verify: build.mutation({
      query: (data: IVerifyData) => ({
        url: '/customers/verify',
        method: 'POST',
        body: data
      })
    }),
    verifyNewEmail: build.mutation({
      query: (data: IVerifyNewEmailData) => ({
        url: '/customers/verify-new-email',
        method: 'POST',
        body: data
      })
    })
  })
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyMutation,
  useVerifyNewEmailMutation
} = authApi
