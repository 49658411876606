import {
  IQueryGetListArticle,
  ResponseArticle,
  ResponseListArticle,
  ResponseListArticleTopPage
} from '@app/types/article'
import { ResponseSettingTopPage } from '@app/types/settingTopPage'
import { getArticleCategory, getArticleType } from '@app/utils/helperFunction'

import { apiService } from './base'

export const articleApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    getListArticle: build.query({
      query: (params: IQueryGetListArticle) => ({
        url: '/list-articles',
        method: 'GET',
        isOptionAuth: true,
        params
      }),
      transformResponse: (baseQueryReturnValue: ResponseListArticle) => ({
        articles: baseQueryReturnValue.data.article.map((article) => ({
          ...article,
          paid_custom: getArticleCategory(article.paid_status),
          category_custom: getArticleType(Number(article?.category?.id))
        })),
        pagination: baseQueryReturnValue.data.pagination
      })
    }),
    getListArticleTopPage: build.query({
      query: (params: IQueryGetListArticle) => ({
        url: '/article/top-page',
        method: 'GET',
        isOptionAuth: true,
        params
      }),
      transformResponse: (
        baseQueryReturnValue: ResponseListArticleTopPage
      ) => ({
        articles: baseQueryReturnValue.data.data
          ? baseQueryReturnValue.data.data.map((article) => ({
              ...article,
              paid_custom: getArticleCategory(article.paid_status),
              category_custom: getArticleType(Number(article?.category?.id))
            }))
          : [],
        pagination: baseQueryReturnValue.data.pagination
      })
    }),
    getArticle: build.query({
      query: (articleSlug: string) => ({
        url: `/articles/detail/${articleSlug}`,
        method: 'GET',
        isOptionAuth: true
      }),
      transformResponse: (baseQueryReturnValue: ResponseArticle) => {
        const {
          article,
          list_editors,
          list_authors,
          recommend_articles,
          semilar_article,
          tags
        } = baseQueryReturnValue.data
        // Disable logic add editor and author of article to list 
        
        // const authorExistInArray = list_authors.find(
        //   (item) => item.name == article.author.name
        // )
        // const editorExistInArray = list_editors.find(
        //   (item) => item.name == article.editor.name
        // )
        // if (!authorExistInArray) {
        //   list_authors.push(article.author)
        // }
        // if (!editorExistInArray) {
        //   list_editors.push(article.editor)
        // }
        console.log({editorLength: list_editors?.length, list_editors:list_editors?.length});
        
        return {
          tags,
          list_editors,
          list_authors,
          semilar_article: semilar_article
            ? semilar_article.map((article) => ({
                ...article,
                paid_custom: getArticleCategory(article?.paid_status),
                category_custom: getArticleType(Number(article?.category?.id))
              }))
            : [],
          recommend_articles: recommend_articles
            ? recommend_articles.map((article) => ({
                ...article,
                paid_custom: getArticleCategory(article?.paid_status),
                category_custom: getArticleType(Number(article?.category?.id))
              }))
            : [],
          article: {
            ...article,
            paid_custom: getArticleCategory(article.paid_status),
            category_custom: getArticleType(Number(article?.category?.id))
          }
        }
      }
    }),
    getSetting: build.query({
      query: () => ({
        url: `/articles/get-setting`,
        method: 'GET',
        isOptionAuth: true
      }),
      transformResponse: (baseQueryReturnValue: ResponseSettingTopPage) =>
        baseQueryReturnValue.data.settings
    })
  })
})

export const {
  useGetListArticleQuery,
  useGetListArticleTopPageQuery,
  useGetArticleQuery,
  useGetSettingQuery
} = articleApi
