// export common key
export const TOKEN_KEY = 'token'
export const REFRESH_TOKEN_KEY = 'refreshToken'
export const CART_KEY = 'cart'
export const CUSTOMER_ORDER_INFO = 'customerOrderInfo'
export const SHIPPING_INFO = 'shipping-info'
export const SHIPPING_INFO_EXIST = 'shipping-info-exist'
export const SHIPPING_CREDIT_CARD = 'shipping-credit-card'
export const SHIPPING_DATA_TYPE = 'shipping-data-type'
export const SHIPPING_ADDRESS = 'shipping-address'
export const IN_CART = 'in-cart'
export const IN_CART_VALUE = 'back-to-cart'
export const IS_BUY_NOW = 'is-buy-now'
export const BUY_NOW_CART = 'buy-now-cart'
export const IS_NEW_CARD = 'is-new-card'
export const PREFECTURE = 'prefecture'
export const EPUB_SETTING = 'epub_setting'
// export common label
export const NODATA = '--'
export const DEFAULT_IMAGE = '/assets/svg/gray-img.svg'
export const GENDER = {
  FEMALE: '女',
  MALE: '男',
  OTHER: 'その他'
}
export const ARTICLE_TYPE = {
  MUSEUM: {
    name: 'ミュージアム通り',
    color: '#6EB0CE'
  },
  FORUM: {
    name: 'フォーラム',
    color: '#82C165'
  },
  BOOKSELLER: {
    name: 'ブックセラー',
    color: '#C19764'
  },
  ATRANDOM: {
    name: 'アトランダム',
    color: '#C96AA1'
  }
}
export const PAID_STATUS_CONVERT = {
  FREE: '無料記事',
  PAID: '有料記事'
}

export const PAID_STATUS = {
  NOT_LOGIN: 'not_login',
  FREE: 'free',
  PAID: 'paid'
}

export const TYPE_OF_ARTICLE = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  BOOK: 'book',
  PR: 'pr',
  MANGA: 'manga'
}

export const DATE_FORMAT = 'YYYY年MM月DD日'
export const MONTH_YEAR = 'MM月YYYY年'
export const YEAR_TIME = 'YYYY年MM月DD日 HH:mm'
export const MONTH_YEAR_SLASH = 'MM月/YYYY年'

export enum BOOK_TYPE {
  ACTUAL_BOOK = 1,
  EBOOK = 2,
  VIDEO = 3,
  AUDIO = 4,
  IMAGE = 5,
  PDF = 6,
  PR = 0
}

// define category type
export const CATEGORIES_BOOK = [
  {
    id: '0',
    label: 'PR'
  },
  {
    id: '1',
    label: '実商品'
  },
  {
    id: '2',
    label: '電子書籍'
  },
  {
    id: '3',
    label: '動画'
  },
  {
    id: '4',
    label: 'オーディオ'
  },
  {
    id: '5',
    label: '画像'
  }
]

// Define category color
export const CATEGORIES_COLOR = [
  {
    id: '1',
    label: '#339933'
  },
  {
    id: '2',
    label: '#D9381A'
  },
  {
    id: '3',
    label: '#166AA7'
  },
  {
    id: '4',
    label: '#951769'
  },
  {
    id: '5',
    label: '#966F04'
  }
]

export const white_list_description = ['IMG']

export const DEFAULT_BOOK_DESCRIPTION = `<p>A book is a world.
それぞれのフィールドで独自の世界を一冊の本につくり
上げてくれる作家たちがいます。そんな作家たちへの憧れ
と尊敬からこのWEBの本屋はできています。</p>`
