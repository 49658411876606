import { ISettingTopPage } from '@app/types/settingTopPage'
import { createSlice } from '@reduxjs/toolkit'
import { articleApi } from '@store/api/article.api'

const name = 'journalSlice'
const initialState: ISettingTopPage = {
  journal_description: '',
  museum_description: '',
  forum_description: '',
  book_description: '',
  genken_ga: ''
}

const journalSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    updateSetting: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      articleApi.endpoints.getSetting.matchFulfilled,
      (state, { payload }) => ({
          ...state,
          ...payload
        })
    )
  }
})

export const { updateSetting } = journalSlice.actions

export default journalSlice.reducer
