import LoadContent from '@components/common/LoadContent'
import React, { Suspense, useEffect } from 'react'

const PublicRoute = ({ children }: any) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <Suspense fallback={<LoadContent />}>{children}</Suspense>
}

export default React.memo(PublicRoute)
