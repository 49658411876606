import { appPaths } from '@app/router/paths'
import { isMobile } from '@app/utils/helperFunction'
import { useWindowWidth } from '@react-hook/window-size'
import StorageServices from '@services/local.storage'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Navigation from './Navigation'
import { listScreenHideSubHeader } from './SubHeader'

function Header() {
  const width = useWindowWidth()
  const isMobileScreen = isMobile(width)
  const location = useLocation()
  const navigate = useNavigate()
  const token = StorageServices.getData('token', undefined)

  const isShowInputSearch = useMemo(() => {
    let result = false
    if (listScreenHideSubHeader.find((item) => item == location.pathname)) {
      result = false
    } else {
      result = true
    }
    return result
  }, [location.pathname])
  const isShowAuthLink = useMemo(() => {
    let result = false
    if (token) {
      result = false
    } else {
      result = true
    }
    return result
  }, [location.pathname])
  return (
    <div
      className={`app-header flex justify-between ${
        !isShowAuthLink && isMobileScreen && 'direction-column height-110'
      }`}
    >
      <img
        src="/assets/png/jounal-header-logo.png"
        alt="app-logo"
        className="app-logo"
        onClick={() => navigate('/')}
      />
      <Navigation
        isShowInputSearch={isShowInputSearch}
        isShowAuthLink={isShowAuthLink}
      />
    </div>
  )
}
export default Header
