export default class StorageServices {
  static getData<T>(key: string, defaultValue: T): T {
    try {
      const jsonData = localStorage.getItem(key)
      if (!jsonData) return defaultValue
      else return JSON.parse(jsonData)
    } catch (err) {
      return defaultValue
    }
  }
  static setData<T>(key: string, value: T): boolean {
    try {
      localStorage.setItem(key, JSON.stringify(value))
      return true
    } catch (err) {
      return false
    }
  }
  static removeData(key: string): boolean {
    try {
      localStorage.removeItem(key)
      return true
    } catch (err) {
      return false
    }
  }
}
