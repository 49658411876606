import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from '../footer/Footer'
import Header from '../header/Header'

/**
 * render layout home page
 * @returns
 */
const GenkenBookSellerLayout = () => (
  <div>
    <div className="genken-homepage-layout">
      <div className="genken-homepage-content">
        <div className="custom-header-genken-homepage-layout">
          <div className="app">
            <div className="app-header">
              <Header />
            </div>
          </div>
        </div>
        <Outlet />
      </div>
      <div className="line-left" />
      <div className="line-right" />
    </div>
    <div className="genken-homepage-layout-footer">
      <Footer />
    </div>
  </div>
)

export default React.memo(GenkenBookSellerLayout)
