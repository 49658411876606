import './style.scss'

import { authPaths } from '@app/router/paths'
import { apiService } from '@store/api/base'
import { useAppDispatch } from '@store/index'
import { logout } from '@store/slices/auth.slice'
import React, { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'

interface AccountMenuProps {
  closeDrawer?: () => void
}

const AccountMenu = ({ closeDrawer }: AccountMenuProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const menu = useMemo(
    () => [
      {
        link: `${authPaths.bookSeller.accountSetting}?tab=my-bookshelf`,
        title: 'マイ本棚（電子書籍）'
      },
      {
        link: `${authPaths.bookSeller.accountSetting}?tab=account-info`,
        title: 'アカウント情報'
      },
      {
        link: `${authPaths.bookSeller.accountSetting}?tab=registration-info`,
        title: '登録情報'
      },
      {
        link: `${authPaths.bookSeller.accountSetting}?tab=credit-card-info`,
        title: 'クレジットカード情報'
      },
      {
        link: `${authPaths.bookSeller.accountSetting}?tab=membership`,
        title: '現研ジャーナル\n月額有料会員'
      },
      {
        link: `${authPaths.bookSeller.accountSetting}?tab=payment-history`,
        title: 'お支払い履歴'
      },
      {
        link: `${authPaths.bookSeller.accountSetting}?tab=withdrawal-procedure`,
        title: '解約・退会手続き'
      }
    ],
    []
  )
  return (
    <div className="bs-account-menu w-236 pb-30 pt-16">
      <div className="flex items-end mb-43 pl-20">
        <img
          src="/assets/svg/person-icon.svg"
          className="w-40 h-40"
          alt="person-icon"
        />
        <span className="text-20 ml-24">マイページ</span>
      </div>
      {menu.map((x, i) => (
        <div
          className="mb-48 pl-20"
          key={`account-menu-${i}`}
        >
          <Link
            onClick={() => closeDrawer && closeDrawer()}
            to={x.link}
            key={i}
            className="account-menu-link leading-24 text-18 cursor-pointer"
          >
            {x.title}
          </Link>
        </div>
      ))}
      <span
        onClick={() => {
          dispatch(apiService.util.resetApiState())
          dispatch(logout())
          closeDrawer && closeDrawer()
          window.location.href = authPaths.bookSeller.signIn
        }}
        className="leading-24 text-18 cursor-pointer pl-20"
      >
        ログアウト
      </span>
    </div>
  )
}

export default React.memo(AccountMenu)
